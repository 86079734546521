/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { IGatsbyImageData, getSrc, getSrcSet } from 'gatsby-plugin-image';
import { Image } from './modules';

export const CMS_Module_Pre_Hooks = [
  {
    type: 'content_module_video_chapters',
    callback: (data: any, _lang: string) => {
      return {
        ...data,
        chapters: data.chapters.map((dc) => {
          return {
            ...getTranslationsForLanguage(dc.chapters_id, _lang),
          };
        }),
      };
    },
  },
  {
    type: 'content_module_image_with_text',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    callback: (data: any, _lang: string) => {
      let img = null;
      if (data.src || typeof data.image === 'string') return data;
      if (!(typeof data.image === 'string')) {
        img = parseGatsbyImage(data.image)?.src || null;
      }
      return {
        ...data,
        image: img,
      };
    },
  },

  {
    type: 'content_module_video_on_demand',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    callback: (data: any, _lang: string) => {
      if (!data.poster) return data;
      return {
        ...data,
        poster: data.poster ? parseGatsbyImage(data.poster) : null,
      };
    },
  },

  {
    type: 'content_module_fullscreen_slogan',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    callback: (data: any, _lang: string) => {
      if (!data.key_visual) return data;
      return {
        ...data,
        key_visual: data.key_visual ? parseGatsbyImage(data.key_visual) : null,
      };
    },
  },
  {
    type: 'content_module_live_stream',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    callback: (data: any, _lang: string) => preHookLivestreamModule(data, _lang),
  },

  {
    type: 'content_module_livestream_teaser',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    callback: (data: any, _lang: string) => {
      return {
        ...data,
        livestream_content_modules: data?.livestream_content_modules?.map((lcm: any) => ({
          ...preHookLivestreamModule(
            getTranslationsForLanguage(lcm.content_module_live_stream_id, _lang),
            _lang,
          ),
        })),
        /*  livestreams:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
          data?.livestreams?.map((livestream: any) => {
            console.log(
              'TRANS',
              getTranslationsForLanguage(livestream.content_module_live_stream_id, _lang)
            );

            return {
              ...getTranslationsForLanguage(livestream.content_module_live_stream_id, _lang),
              card_image: livestream.content_module_live_stream_id.card_image
                ? parseGatsbyImage(livestream.content_module_live_stream_id.card_image)
                : null,
            };
          }) || null, */
      };
    },
  },

  {
    type: 'content_module_accordion',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data: any, lang: string) => {
      return {
        ...data,
        groups:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data?.groups?.map((group: { content_module_accordion_group_id: { entries: any[] } }) => ({
            ...getTranslationsForLanguage(group?.content_module_accordion_group_id, lang),
            entries:
              group?.content_module_accordion_group_id?.entries?.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (entry: { content_module_accordion_entry_id: any }) =>
                  getTranslationsForLanguage(entry?.content_module_accordion_entry_id, lang),
              ) || [],
          })) || [],
        entries:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data?.entries?.map((entry: { content_module_accordion_entry_id: any }) =>
            getTranslationsForLanguage(entry?.content_module_accordion_entry_id, lang),
          ) || [],
      };
    },
  },
  {
    type: 'content_module_virtual_tour_selection',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data: any, lang: string) => {
      return {
        ...data,
        ...getTranslationsForLanguage(data, lang),
        tours:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data?.tours
            ?.filter(({ tour_id }) => tour_id !== null)
            .map(({ tour_id }: any) => {
              const ret = {
                ...tour_id,
                ...getTranslationsForLanguage(tour_id, lang),
              };
              delete ret.translations;
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const scene = ret.steps?.[0]?.scene?.id || null;
              delete ret.steps;

              return {
                ...ret,
                image: ret.image ? parseGatsbyImage(ret.image) : null,
                scene,
              };
            }) || [],
      };
    },
  },
  {
    type: 'content_module_video_slider',
    callback: (data: any, lang: string) => ({
      ...data,
      videos: data?.videos.map((v) => {
        const video = v.content_module_video_slider_video_id;
        return {
          ...getTranslationsForLanguage(video, lang),
          poster: video.poster ? parseGatsbyImage(video.poster) : null,
        };
      }),
    }),
  },
  {
    type: 'content_module_survey',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data: any, lang: string) => preHookSurvey(data, lang),
  },
  {
    type: 'content_module_event_teaser',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data: any, lang: string) => ({
      ...data,
      survey: data.survey ? preHookSurvey(data.survey, lang) : null,
    }),
  },
  {
    type: 'content_module_survey_with_event_teaser',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data: any, lang: string) => ({ ...data, survey: preHookSurvey(data.survey, lang) }),
  },
  {
    type: 'content_module_video_gallery',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data: any, lang: string) => preHookVideoGallery(data, lang),
  },

  {
    type: 'content_module_story',
    callback: (data: any) => ({
      ...data,
      stories: data?.stories.map((v) => {
        return v.directus_files_id ? parseGatsbyImage(v.directus_files_id) : null;
      }),
    }),
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const preHookSurvey = (data: any, lang: string) => {
  return {
    ...data,

    questions:
      data?.questions?.map(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ({ content_module_survey_questions_id: { translations, options, ...question } }) => {
          return {
            ...question,
            ...getTranslationsForLanguage({ ...question, translations }, lang),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            options: options.map(({ translations, ...option }) => {
              const trans = getTranslationsForLanguage({ ...option, translations }, lang);
              return {
                ...option,
                ...trans,
                image: trans.image ? parseGatsbyImage(trans.image) : null,
              };
            }),
          };
        },
      ) || [],
  };
}; // eslint-disable-next-line @typescript-eslint/no-explicit-any
const preHookLivestreamModule = (data: any, lang: string) => {
  return {
    ...data,
    session_display: data?.session_display?.map((sd) => ({
      ...getTranslationsForLanguage(sd.content_module_event_display_id, lang),
    })),
    livestream_channel: {
      ...data.livestream_channel,
      categories:
        data.livestream_channel?.categories?.map((category) => ({
          ...category.categories_id,
        })) || [],
      category_slugs:
        data.livestream_channel?.categories?.map((category) => category.categories_id?.slug) || [],
    },
    card_image: data.card_image ? parseGatsbyImage(data.card_image) : null,
    offset_start: data.offset_start || 0,
    offset_end: data.offset_end || 0,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTranslationsForLanguage(pageTranslations: any, langCode: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { translations: trans, ...rest } = pageTranslations;

  if (!trans) return pageTranslations;
  const translationForCurrentLanguage = trans?.find(
    (t: { languages_code: { code: string } }) =>
      t.languages_code.code === langCode || t.languages_code.code.split('-')?.[0] === langCode,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { languages_code, ...translations } = translationForCurrentLanguage || {};
  return { ...rest, ...translations };
}

const preHookVideoGallery = (data: any, lang: string) => {
  return {
    ...data,
    videos: data.videos.map((d) => {
      const video = d.content_module_video_gallery_video_id;
      return {
        ...getTranslationsForLanguage(video, lang),
        poster: video.poster ? parseGatsbyImage(video.poster) : null,
        //getTranslationsForLanguage
      };
    }),
  };
};

export const parseGatsbyImage = ({
  id,
  title,
  width,
  height,
  filesize,
  imageFile,
}: Omit<Image, 'src' | 'srcSet'> & { imageFile: IGatsbyImageData }): Image => {
  return {
    id,
    title,
    width,
    height,
    filesize,
    src: getSrc(imageFile) || '',
    srcSet: getSrcSet(imageFile),
  };
};
