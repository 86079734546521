import { AdhocService } from '@project/cue-api/adhoc';
import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
const POLLING_INTERVAL_MS = 2000;
export enum AdhocStateType {
  QUEUED = 'queued',
  REJECTED = 'rejected',
  TIMEOUT = 'timeout',
  CONFIRMED = 'confirmed',
}
export type AdhocState = {
  since: string | null;
  status: AdhocStateType | null;
  meetingURL: string | null;
};
export const useAdhocDefaultState = {
  status: {
    since: null,
    status: null,
    meetingURL: null,
  },
  startChecking: () => undefined,
  stopChecking: () => undefined,
  cancelRequest: () => undefined,
};

export const _useAdhocImpl = () => {
  const [status, setStatus] = useState<AdhocState>({
    since: null,
    status: null,
    meetingURL: null,
  });
  let intervalId: Timer;

  const checkAdhocStatus = async () => {
    const status = await AdhocService.getStatus();
    setStatus(status);
    if (
      [AdhocStateType.CONFIRMED, AdhocStateType.TIMEOUT, AdhocStateType.REJECTED].includes(
        status.status,
      )
    ) {
      stopChecking();
    }
    return status;
  };
  const startChecking = () => {
    async () => {
      checkAdhocStatus();
    };
    intervalId = setInterval(checkAdhocStatus, POLLING_INTERVAL_MS);
  };

  const cancelRequest = async () => {
    try {
      await AdhocService.deleteRequest();
      await checkAdhocStatus();
      stopChecking && stopChecking();
    } catch (e) {
      console.error(e);

      return;
    }
  };

  const stopChecking = () => {
    intervalId && clearInterval(intervalId);
  };

  useEffect(() => {
    checkAdhocStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { status, startChecking, stopChecking, cancelRequest };
};

export const useAdhoc = singletonHook<useAdhocReturn>(useAdhocDefaultState, _useAdhocImpl, {
  unmountIfNoConsumers: true,
});

export interface useAdhocReturn {
  status: AdhocState;
  startChecking: () => void;
  stopChecking: () => void;
  cancelRequest: () => void;
}
