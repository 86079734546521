import { LocalStorageUtil, isE2ETesting } from '@cue/utility';
import { useEffect } from 'react';
//TODO

/* eslint-disable */

declare global {
  interface Window {
    interacted: boolean;
    Matomo: any;
    _paq: any;
  }
}

export interface TrackingData {
  type: 'media' | 'event';
  name?: string;
  src?: string;
  action?: string;
  category?: string;
  data?: {
    title?: string;
    progressInSeconds?: number;
    totalLengthInSeconds?: number;
  } | null;
  context?: {
    uid?: string;
    topics?: Array<string>;
  };
  source?: string | null;
}
type JSONValue = string | number | boolean | { [x: string]: JSONValue } | Array<JSONValue>;
export type TrackEventProps = {
  category: string;
  type: string;
  action: string;
  data?: JSONValue;
};

export class AnalyticsTransformer {
  private MA: any;
  private tracker: any;
  private playerName = 'Default Player';
  private currentData: { src: string; title: string } | null = null;
  public static checkTracking() {
    if (!window._paq) console.warn('No Matomo Found');
  }
  public static setUserData() {
    const { userId, customDimensions } =
      (LocalStorageUtil.get('user') as {
        userId: null;
        customDimensions: null;
      }) || {};

    console.info('MATOMO USER: ', userId, customDimensions);
    try {
      if (!window._paq) {
        window._paq = [];
      }

      if (userId) {
        window._paq.push(['setUserId', userId]);
      }

      if (customDimensions) {
        window._paq.push(['setCustomDimension', 1, customDimensions]);
      }
      window._paq.push(['trackPageView']);
    } catch (error) {
      console.warn(error);
    }
  }

  public static scanForMedia() {
    try {
      if (!window._paq) {
        window._paq.push(['MediaAnalytics::enableMediaAnalytics']);
        window._paq.push(['MediaAnalytics::scanForMedia']);
        console.info('MATOMO SCAN FOR MEDIA');
      }
    } catch (error) {
      console.warn(error);
    }
  }
  public static getMediaTracker() {
    return typeof window !== 'undefined' && window.Matomo ? window.Matomo.MediaAnalytics : null;
  }

  // tslint:disable-next-line: no-any
  /**
   * @deprecated Use AnalyticsTransformer.trackEvent instead
   */

  public static customEvent(params: any = [], sameWindow: boolean = false) {
    AnalyticsTransformer.checkTracking();
    try {
      // if tracking comes from within an iframe, use window.top instead of window
      // and if parent is not E2E
      if (window.top && !sameWindow && !isE2ETesting()) {
        window.top._paq.push(['trackEvent', ...params]);
        console.debug('Event', ['trackEvent', ...params], '(using window.top)');

        return;
      }

      console.debug('Event', ['trackEvent', ...params]);
      window._paq.push(['trackEvent', ...params]);
    } catch (error) {
      console.debug(error);
    }
  }

  public static trackEvent(props: TrackEventProps, sameWindow: boolean = false) {
    const { category, type, action, data } = props;

    const tracking = [category, `${type}:${action}`, JSON.stringify(data || {})];
    AnalyticsTransformer.checkTracking();

    try {
      // if tracking comes from within an iframe, use window.top instead of window
      // and if parent is not E2E
      if (window.top && !sameWindow && !isE2ETesting()) {
        window.top._paq.push(['trackEvent', ...tracking]);
        console.debug('Event', ['trackEvent', ...tracking], '(using window.top)');

        return;
      }

      console.debug('Event', ['trackEvent', ...tracking]);
      window._paq.push(['trackEvent', ...tracking]);
    } catch (error) {
      console.debug(error);
    }
  }

  // tslint:disable-next-line: no-any
  public static customKeyEvent(key: string, params: any = []) {
    AnalyticsTransformer.checkTracking();

    try {
      // if tracking comes from within an iframe, use window.top instead of window
      if (window.top && !isE2ETesting()) {
        window.top._paq.push([key, ...params]);
        console.debug('Event', [key, ...params], '(using window.top)');

        return;
      }

      console.debug('Event', [key, ...params]);
      window._paq.push([key, ...params]);
    } catch (error) {
      console.debug(error);
    }
  }

  public constructor() {
    if (typeof window !== 'undefined' && window.Matomo) {
      this.MA = window.Matomo.MediaAnalytics;
    }
  }
  public transformTrackingEvent = (data: TrackingData) => {
    const { type } = data;
    // MEDIA
    //@ts-ignore
    if (data?.data?.analytics?.type === 'media') {
      //@ts-ignore

      this.transformMediaEvent(data?.data?.analytics);
    } else {
      console.log('transformTrackingEvent type', data);
      if (type === 'event') {
        console.log('transformTrackingEvent', 'transformEvent');
        const { action, category, data: _analytics_data } = data;
        //@ts-ignore
        const parsedAnalytics = _analytics_data?.analytics || _analytics_data;
        // Validate
        //@ts-ignore
        if (action && category) this.transformEvent({ action, category, data: parsedAnalytics });
      }
    }
  };

  public setPlayername = (playerName?: string | null) => {
    if (playerName) this.playerName = playerName;
  };

  private mediaTrackLoadedmetadata = ({ target }: any) => {
    this.transformMediaEvent({
      name: 'start',
      type: 'media',
      src: this.currentData?.src,
      data: {
        title: this?.currentData?.title,
        totalLengthInSeconds: target.duration,
      },
    });
  };
  private mediaTrackPlay = () => {
    this.transformMediaEvent({
      type: 'media',
      name: 'play',
    });
  };
  private mediaTrackPause = () => {
    this.transformMediaEvent({
      type: 'media',
      name: 'pause',
    });
  };

  private mediaTrackSeeked = ({ target }: any) => {
    this.transformMediaEvent({
      type: 'media',
      name: 'seeked',
      data: {
        progressInSeconds: target.currentTime,
        totalLengthInSeconds: target.duration,
      },
    });
  };

  private mediaTrackTimeupdate = ({ target }: any) => {
    this.transformMediaEvent({
      type: 'media',
      name: 'timeupdate',
      data: {
        progressInSeconds: target.currentTime,
        totalLengthInSeconds: target.duration,
      },
    });
  };

  private mediaTrackEnded = () => {
    this.transformMediaEvent({
      type: 'media',
      name: 'ended',
    });
  };

  public bindMediaTracking = (
    ref: HTMLVideoElement,
    args: { src: string; title: string } | null,
  ) => {
    console.debug('Matomo Bind Media Analytics', args);
    this.currentData = args;
    ref.addEventListener('loadedmetadata', this.mediaTrackLoadedmetadata);
    ref.addEventListener('play', this.mediaTrackPlay);
    ref.addEventListener('pause', this.mediaTrackPause);
    ref.addEventListener('ended', this.mediaTrackEnded);
    ref.addEventListener('seeked', this.mediaTrackSeeked);
    ref.addEventListener('timeupdate', this.mediaTrackTimeupdate);
  };

  public unbindMediaTracking = (ref: HTMLVideoElement) => {
    if (!ref) {
      return;
    }
    console.debug('Unbind Media Analytics');
    this.currentData = null;
    ref.removeEventListener('loadedmetadata', this.mediaTrackLoadedmetadata);
    ref.removeEventListener('play', this.mediaTrackPlay);
    ref.removeEventListener('pause', this.mediaTrackPause);
    ref.removeEventListener('ended', this.mediaTrackEnded);
    ref.removeEventListener('seeked', this.mediaTrackSeeked);
    ref.removeEventListener('timeupdate', this.mediaTrackTimeupdate);
  };

  transformEvent = ({ category, action, data }: TrackingData) => {
    const trackData = [`${category}`, `${action}`, `${JSON.stringify(data)}`];
    console.debug('Event', ['trackEvent', ...trackData]);
    if (category !== undefined && action !== undefined) {
      window._paq.push(['trackEvent', ...trackData]);
    } else {
      throw new Error('Analytics: Category and Action are required');
    }
  };

  transformMediaEvent = (input: TrackingData) => {
    const { name, src, data, context, source = null } = input;

    const { topics: [altTitle] = [] } = context || {};
    const {
      title = '',
      totalLengthInSeconds = 0,
      progressInSeconds = 0,
    } = data || { title: '', totalLengthInSeconds: 0, progressInSeconds: 0 };

    if (!this.MA) {
      this.MA = window?.Matomo?.MediaAnalytics;
    }

    if (!this.MA) {
      return false;
    }
    switch (name) {
      case 'start':
      case 'load':
        if (source) {
          this.playerName = source;
        }
        this.tracker = new this.MA.MediaTracker(this.playerName, this.MA.mediaType.VIDEO, src);
        const _title = title || altTitle || src;
        this.tracker.setMediaTitle(
          JSON.stringify(context ? { ...context, title: _title } : { title: _title }),
        );
        console.debug(
          'MEDIA ANALYTICS TITLE',
          JSON.stringify(context ? { ...context, title: _title } : { title: _title }),
        );
        this.tracker.setMediaTotalLengthInSeconds(totalLengthInSeconds);
        this.tracker.play(); // need to come from showroom

        break;
      default:
        if (!this.tracker) {
          console.warn('Matomo Media was not initialized');
          console.debug('Matomo Media was not initialized');

          return false;
        }
        switch (name) {
          case 'play':
          case 'video:play':
            console.debug('MEDIA ANALYTICS play');

            this.tracker.play();
            break;
          case 'pause':
          case 'video:pause':
            console.debug('MEDIA ANALYTICS pause');

            this.tracker.pause();
            break;
          case 'ended':
          case 'video:ended':
            console.debug('MEDIA ANALYTICS ended');

            this.tracker.finish();
            break;
          case 'timeupdate':
          case 'video:timeupdate':
            console.debug('MEDIA ANALYTICS timeupdate');

            this.tracker.setMediaProgressInSeconds(progressInSeconds);
            this.tracker.setMediaTotalLengthInSeconds(totalLengthInSeconds);
            this.tracker.update();
            break;
          case 'seeked':
          case 'video:seeked':
            console.debug('MEDIA ANALYTICS seeked');

            this.tracker.seekStart();
            this.tracker.setMediaProgressInSeconds(progressInSeconds);
            this.tracker.setMediaTotalLengthInSeconds(totalLengthInSeconds);
            this.tracker.seekFinish();
            break;
          default:
            return;
        }
    }
  };
}

export type LoadAnalyticsProps = {
  whitelist: string[];
};
export const LoadAnalytics = ({ whitelist = [] }: LoadAnalyticsProps) => {
  let tracking: AnalyticsTransformer;
  useEffect(() => {
    tracking = new AnalyticsTransformer();
    window.removeEventListener('message', listenFrameEvent);

    window.addEventListener('message', listenFrameEvent);
    return () => {
      window.removeEventListener('message', listenFrameEvent);
    };
  }, []);

  const listenFrameEvent = (frameEvent: any) => {
    const { data, origin } = frameEvent;
    if (whitelist.length > 0 && !whitelist.includes(origin)) {
      return;
    }

    const source = 'Fairtouch';
    if (tracking) {
      tracking.transformTrackingEvent({ ...data, source });
    }
  };

  return <span style={{ display: 'none' }}>{/* Analytics */}</span>;
};
