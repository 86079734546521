// import { SHOWROOM_TRACKING_PRE } from '../video-platform/VideoPlatformContainer';
import { AdhocExperts, AdhocExpertsProps } from './adhoc-experts';
import { useAdhocSettings } from './use-adhoc-settings';
import { Icon, Select } from '@cue/atoms';
import { Paragraph } from '@cue/atoms';
import { Button } from '@cue/atoms';
import { LoadingSpinner } from '@cue/atoms';
import { getTranslationsForLanguage } from '@project/cms/pre-hooks';
import { AdhocService } from '@project/cue-api//adhoc';
import { useFlag } from '@project/hooks/use-flag';
import { useResendAccess } from '@project/hooks/use-resend-access';
import { useUser } from '@project/hooks/use-user';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAdhoc } from './use-adhoc';

export const INITIAL_STATUS = null;

interface DataInitial {
  since: null;
  status: typeof INITIAL_STATUS;
  meetingURL: null;
}

interface DataQueued {
  since: string;
  status: 'queued';
  meetingURL: null;
}

interface DataRejected extends Omit<DataQueued, 'status'> {
  status: 'rejected';
}

interface DataTimeout extends Omit<DataQueued, 'status'> {
  status: 'timeout';
}

interface DataConfirmed {
  since: string;
  status: 'confirmed';
  meetingURL: string;
}

interface DataCancelled {
  since: string;
  status: 'canceled';
  meetingURL: null;
}

type AdHocData =
  | DataInitial
  | DataQueued
  | DataConfirmed
  | DataRejected
  | DataCancelled
  | DataTimeout;

interface AdhocProps {
  initialState?: AdHocData;
  selectedCategorySlug?: string;
  selectedTour?: unknown;
  compact?: boolean;
  trackingObject?: unknown;
}

const isDataQueued = (x: unknown): x is DataQueued => {
  const { since, status } = x as DataQueued;

  return typeof since === 'string' && status === 'queued';
};

const isDataRejected = (x: unknown): x is DataRejected => {
  const { status } = x as DataRejected;

  return status === 'rejected';
};

const isDataTimeout = (x: unknown): x is DataTimeout => {
  const { status } = x as DataTimeout;

  return status === 'timeout';
};

const isDataConfirmed = (x: unknown): x is DataConfirmed => {
  const { status, meetingURL } = x as DataConfirmed;

  return status === 'confirmed' && typeof meetingURL === 'string';
};

const isDataCancelled = (x: unknown): x is DataCancelled => {
  const { status } = x as DataCancelled;

  return status === 'canceled';
};

const DEFAULT_INITIAL_STATE: DataInitial = {
  meetingURL: null,
  since: null,
  status: INITIAL_STATUS,
};

const POLLING_INTERVAL_MS = 2000;

const COUNTRY_TAG = (country: string) => `Country: ${country}`;

type SelectTopic = {
  value: string;
  label: string;
};

type SelectLang = {
  value: string;
  label: string;
};

function topicToSelectValue(topic: { id: string; slug: string; title: string } | undefined) {
  if (!topic) return undefined;
  return { value: topic.slug, label: topic.title };
}

/* eslint-disable sort-exports/sort-exports */
export const AdhocTalkRequest: React.FC<AdhocProps> = ({
  initialState = DEFAULT_INITIAL_STATE,
  selectedCategorySlug,
}) => {
  const { setIsOpen: setResendAccessModalOpen } = useResendAccess();
  const adhocSettings = useAdhocSettings();
  const { startChecking, stopChecking } = useAdhoc();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [noTopicError, setNotOpicError] = useState<string>();

  const { t, i18n } = useTranslation();
  const isAdhocAvailable = useFlag(adhocSettings.adhocTimesFlag);
  const tagOptions = adhocSettings.topics;

  const langOptions = React.useMemo(() => {
    const langOptionsInCurrentLanguage = adhocSettings.languages.map((l) => ({
      ...l,
      ...getTranslationsForLanguage(l, i18n.language),
    }));

    return langOptionsInCurrentLanguage;
  }, [adhocSettings.languages, i18n.language]);

  const CATEGORY_TAG = adhocSettings.appointmentType || 'Please add an appointment Type in the CMS';

  function slugToTopic(slug: string) {
    return tagOptions.find((o) => o.slug === slug);
  }

  const { user } = useUser();

  const [selectedTag, setSelectedTag] = useState<SelectTopic | undefined>();
  // selectedCategorySlug
  //   ? topicToSelectValue(tagOptions?.find((c) => c.slug === selectedCategorySlug))
  //   : topicToSelectValue(tagOptions[0]),
  const [selectedLang, setSelectedLang] = React.useState<SelectLang | undefined>(
    langOptions
      .map((l) => ({ value: l.key, label: l.name }))
      .find((l) => {
        return l.value === i18n.language;
      }),
  );

  React.useEffect(() => {
    setSelectedLang(
      langOptions
        .map((l) => ({ value: l.key, label: l.name }))
        .find((l) => {
          return l.value === i18n.language;
        }),
    );
  }, [i18n.language]);

  const [adHocState, setAdHocState] = useState<AdHocData>(initialState);

  const intervalIdRef = useRef<number>();

  React.useEffect(() => {
    if (selectedCategorySlug && selectedTag?.value !== selectedCategorySlug) {
      const topic = slugToTopic(selectedCategorySlug);
      const selected = topicToSelectValue(topic);
      setSelectedTag(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategorySlug]);

  const handleCancel = useCallback(async () => {
    try {
      await AdhocService.deleteRequest();
      stopChecking && stopChecking();
    } catch (e) {
      console.error(e);

      return;
    }

    setAdHocState(initialState);
    if (intervalIdRef.current) {
      window.clearInterval(intervalIdRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  const handleClose = () => {
    setAdHocState(initialState);
    if (intervalIdRef.current) {
      window.clearInterval(intervalIdRef.current);
    }
  };

  /*  const getTagTextBySlug = (slug: string | undefined): string => {
    if (!slug) return '';
    return tagOptions.find((c) => c.slug === slug)?.title || '';
  }; */

  const handleTransitionInitial = async () => {
    if (!selectedTag) {
      setNotOpicError(t('ad-hoc.no-topic-error'));
      return;
    }

    if (error) setError(undefined);
    setIsLoading(true);
    try {
      if (!user) {
        setIsLoading(false);
        return;
      }

      const languageTag = selectedLang ? `#${selectedLang.value.toUpperCase()}` : '#DE';

      const selectedTagText = `Topic: ${selectedTag?.label}`;

      const responseSendRequest = await AdhocService.sendRequest([
        selectedTagText,
        languageTag,
        COUNTRY_TAG(user.fields.country),
        CATEGORY_TAG,
      ]);

      if (responseSendRequest === false) {
        setError(t('ad-hoc.error'));
        setIsLoading(false);
        return;
      }

      if (responseSendRequest) {
        const responseGetStatus = await AdhocService.getStatus();
        startChecking && startChecking();
        if (isDataQueued(responseGetStatus)) {
          setAdHocState(responseGetStatus);
          setIsLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  // this useEffect handles the queued state, in an interval of the length of POLLING_INTERVAL_MS
  // the getStatus function is called until the request is either rejected or confirmed
  useEffect(() => {
    if ((adHocState.status !== 'queued' && adHocState.status !== null) || !isAdhocAvailable) {
      return;
    }
    if (adHocState.status === null) return;

    const intervalId = window.setInterval(async () => {
      const responseGetStatus = await AdhocService.getStatus();

      if (
        isDataRejected(responseGetStatus) ||
        isDataTimeout(responseGetStatus) ||
        isDataConfirmed(responseGetStatus) ||
        isDataCancelled(responseGetStatus)
      ) {
        setAdHocState(responseGetStatus);
      }

      if (isDataConfirmed(responseGetStatus)) {
        window.setTimeout(() => {
          window.open(responseGetStatus.meetingURL, '_blank');
        }, 5);
      }
    }, POLLING_INTERVAL_MS);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [adHocState, isAdhocAvailable]);

  // set initial state
  // if the current status is either confirmed or queued, it should get updated
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await AdhocService.getStatus();
      setIsLoading(false);
      if (!response) {
        return;
      }

      const { status } = response;

      if (status !== 'confirmed' && status !== 'queued') {
        return;
      }
      setAdHocState(response);
    })();
  }, []);

  const onChangeTopic = useCallback((e: SelectTopic) => {
    setSelectedTag(e);
  }, []);

  const openPage = (url: string) => {
    window.open(url, '_blank');
  };

  const adhocExperts = useMemo(() => {
    if (adhocSettings?.experts?.length > 4) {
      const experts = adhocSettings?.experts;
      const randomExperts: AdhocExpertsProps[] = [];
      while (randomExperts.length < 4) {
        const randomIndex = Math.floor(Math.random() * experts.length);
        const randomExpert = experts[randomIndex];
        if (!randomExperts.includes(randomExpert)) {
          randomExperts.push(randomExpert);
        }
      }

      return randomExperts.map((expert) => (
        <li key={expert.id}>
          <AdhocExperts {...expert} />
        </li>
      ));
    }

    return adhocSettings?.experts?.map((expert) => (
      <li key={expert.id}>
        <AdhocExperts {...expert} />
      </li>
    ));
  }, []);

  /*   <pre>{JSON.stringify(expert, null, 2)}</pre>
   */ {
    /* <Avatar
        speaker={{
          ...speaker,
          occupation: `${speaker.occupation} ${speaker.company ? `(${speaker.company})` : ''}`,
          image: speaker.profileImageURL || fallBackImage,
        }}
        onClick={
          agendaDisplayOptions?.speakerAgenda
            ? () => {
                setIsModalOpen(true);
              }
            : undefined
        }
      /> */
  }
  return (
    <div className="adhoc">
      {!isAdhocAvailable && (
        <>
          <div className="adhoc__title">{t('ad-hoc.unavailable.headline')}</div>
          <Paragraph className="adhoc__subtitle">{t('ad-hoc.unavailable.subline')}</Paragraph>

          <Button onClick={() => openPage(t('ad-hoc.unavailable.cta.uri'))} className="adhoc__cta">
            {t('ad-hoc.unavailable.cta.label')}
          </Button>
        </>
      )}
      {adHocState.status === INITIAL_STATUS && isAdhocAvailable && (
        <div className="adhoc_content-wrapper">
          <div className="adhoc__title">{t('ad-hoc.headline')}</div>
          <Paragraph className="adhoc__subtitle">{t('ad-hoc.subline')}</Paragraph>

          <div className="adhoc__controls">
            {adhocExperts && (
              <div className="adhoc-speaker-list">
                {/* <div className=" adhoc__title adhoc__title-experts">{t('adhoc.experts')}</div> */}
                <ul>{adhocExperts}</ul>
              </div>
            )}
            <div className="adhoc__select-wrapper">
              <p className="adhoc__select-topic">{t('ad-hoc.select-topic')}</p>
              <Select
                options={tagOptions.map((o) => ({ value: o.slug, label: o.title }))}
                value={selectedTag}
                onChange={(newValue) => {
                  onChangeTopic(newValue as SelectTopic);
                  // if (!user) {
                  //   setResendAccessModalOpen(true);
                  // }
                }}
                onFocus={() => setNotOpicError(undefined)}
                error={Boolean(noTopicError)}
                // errorMessage={noTopicError}
                placeholder={t('ad-hoc.select-topic-placeholder')}
              />

              <Select
                options={langOptions.map((o) => ({ value: o.key, label: o.name }))}
                value={selectedLang}
                onChange={(newValue) => {
                  setSelectedLang(newValue as SelectLang);
                  // if (!user) {
                  //   setResendAccessModalOpen(true);
                  // }
                }}
                placeholder={t('ad-hoc.select-lang-placeholder')}
              />
            </div>
            <div className="adhoc__btn-wrapper">
              <Button
                disabled={isLoading}
                onClick={() =>
                  user ? handleTransitionInitial() : setResendAccessModalOpen({ isAdhoc: true })
                }
                className="adhoc__cta">
                {t('ad-hoc.request-session.cta')}
              </Button>
            </div>
          </div>
        </div>
      )}
      {adHocState.status === 'queued' && isAdhocAvailable && (
        <div className="adhoc_content-wrapper">
          <div className="adhoc__title">{t('ad-hoc.queue.headline')}</div>
          <div className="adhoc__subtitle">{t('ad-hoc.queue.subline')}</div>
          <div className="adhoc__controls">
            <div className="adhoc__spinner">
              <LoadingSpinner />
            </div>

            <div className="adhoc__btn-wrapper">
              <Button onClick={handleCancel} className="adhoc__cta">
                {t('ad-hoc.queue.cta')}
              </Button>
            </div>
          </div>
        </div>
      )}
      {adHocState.status === 'confirmed' && isAdhocAvailable && (
        <div className="adhoc_content-wrapper">
          <div className="adhoc__title">{t('ad-hoc.success.headline')}</div>
          <div className="adhoc__subtitle">{t('ad-hoc.success.subline')}</div>
          <div className="adhoc-modal-icon">
            <div>
              <Icon name="check" />
            </div>
          </div>

          <div className="adhoc__btn-wrapper">
            {/* <CancelButton secondary onClick={handleCancel}>
              {t('ad-hoc.success.cancel')}
            </CancelButton> */}
            <a
              href={adHocState.meetingURL || ''}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClose}>
              <Button className="adhoc__cta">{t('ad-hoc.success.to-meeting.cta')}</Button>
            </a>
          </div>
        </div>
      )}
      {(adHocState.status === 'canceled' ||
        adHocState.status === 'rejected' ||
        adHocState.status === 'timeout') &&
        isAdhocAvailable && (
          <div className="adhoc_content-wrapper">
            {/* <Button onClick={handleClose}>Close &#10005;</Button> */}
            <div className="adhoc__title">{t('ad-hoc.reject.headline')}</div>
            <div className="adhoc__subtitle">{t('ad-hoc.reject.subline')}</div>
            <div className="adhoc__controls adhoc__controls--end">
              <div className="adhoc__btn-wrapper">
                <Button
                  disabled={isLoading}
                  icon="refresh"
                  onClick={handleTransitionInitial}
                  className="adhoc__cta">
                  {t('ad-hoc.reject.cta')}
                </Button>
              </div>
            </div>
          </div>
        )}
      {error ? <div className="adhoc__error">{error}</div> : null}
    </div>
  );
};
